import {React, useState, useEffect} from 'react';
import './App.css';

import "primereact/resources/themes/vela-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { useCookies } from 'react-cookie';
import { v4 as uuid } from 'uuid';

import { Menubar } from 'primereact/menubar';
import { HeaderStart } from './components/headerStart';
import { MatchTable } from './components/matchTable';
import { MatchCalendar } from './components/matchCalendar';
import { PickTable } from './components/pickTable';
import { getLeagues } from "./api/";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

let App = () =>  {
  const [leagues, setLeagues] = useState([]);
  const [cookies, setCookie] = useCookies(['user']);
  const [isDekstop, setIsDesktop] = useState(true);
  const items = [{label: 'Home', url:'/'},
                  {label: 'UEFA Champions League', url: '/league/europe/champions-league'},
                  {label: 'Premier League', url: '/league/england/premier-league'},
                  {label: 'La Liga', url: '/league/spain/laliga'},
                  {label: 'Serie A', url: '/league/italy/serie-a'},
                  {label: 'Bundesliga', url: '/league/germany/bundesliga'},
                  {label: 'Ligue 1', url: '/league/france/ligue-1'},
                  {label: 'Europa League', url: '/league/europe/europa-league'},
                  {label: 'Conference League', url: '/league/europe/conference-league'},
                  {label: 'Copa Libertadores', url: '/league/south-america/copa-libertadores'},
                  {label: 'FA Cup', url: '/league/england/fa-cup'},
                  {label: 'EFL Cup', url: '/league/england/efl-cup'},
                  //{label: 'Brazil Serie A', url: '/league/brazil/serie-a-betano'},
                  //{label: 'MLS', url: '/league/usa/mls'},
                  //{label: 'Leagues Cup', url: '/league/north-central-america/leagues-cup'},
                  {label: 'My Picks', url: '/mypicks'}
                ];
  const start = <HeaderStart leagues={leagues}/>;
  const end = <MatchCalendar/>;

  const firebaseConfig = {
    apiKey: "AIzaSyAsJ-iX-iIkb7ihlQeq6JpFBD1chckihLA",
    authDomain: "able-the-fanatic.firebaseapp.com",
    projectId: "able-the-fanatic",
    storageBucket: "able-the-fanatic.appspot.com",
    messagingSenderId: "571116718172",
    appId: "1:571116718172:web:ccca1267e72a26c34cbe99",
    measurementId: "G-LJV55YL51J"
  };

  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  async function getAllLeagues() {
    const allLeagues = await getLeagues();
    setLeagues(allLeagues);
  };

  useEffect(
    () => {
      setIsDesktop(window.innerWidth > 1000);
      console.log(window.innerWidth > 1000);
      getAllLeagues();
      if (cookies.UserToken) {
        const userCooker = cookies.UserToken;
        console.log(userCooker);
      }
      else {
        const userCooker = uuid();
        console.log(userCooker);
        setCookie('UserToken', userCooker, { path: '/'});
      }
    },[]
  );

  return (
    <Router className="page-header">
      <Routes>
        <Route path="/" element={
          <div>
            <Menubar model={items} start={start} end={end}/>
            <MatchTable isDesktop={window.innerWidth > 1000}/>
          </div>
        } />
        <Route path="/league/:area/:league" element={
          <div>
            <Menubar model={items} start={start} end={end}/>
            <MatchTable isDesktop={window.innerWidth > 1000}/>
          </div>
        } />
        <Route path="/date/:date" element={
          <div>
            <Menubar model={items} start={start} end={end}/>
            <MatchTable isDesktop={window.innerWidth > 1000}/>
          </div>
        } />
        <Route path="/mypicks" element={
          <div>
            <Menubar model={items} start={start} end={end}/>
            <PickTable userCookie={cookies.UserToken}/>
          </div>
        } />
      </Routes>
    </Router>
  );
}

export default App;

/*
{label: 'About Picks'},
<Toast ref={toast}></Toast>
<Menu model={items} popup ref={menu} />
<Button label="Show" icon="pi pi-bars" onClick={(e) => menu.current.toggle(e)} />
className="App bg-blue-500" style={{ backgroundColor: 'var(--primary-color)', color: 'var(--primary-color-text)'}}
className ="bg-blue-500"
<header className="App-header">
  Test
</header>
<Calendar value={date} onChange={(e) => setDate(e.value)} inline showWeek />
\<InputText placeholder="Search" type="text" className="w-full" />
*/
